<template>
  <div>
    <div style="margin: 20px 0 0px 20px">
      <MyBreadcrumb></MyBreadcrumb>
    </div>
    <div class="role-main">

      <Table border :columns="columns" :loading="loading" :data="data"></Table>
      <div class="page">
        <Page :total="total" @on-change="changePage" :current="page" show-total show-sizer show-elevator></Page>
      </div>
      <DeleModal :status="deleModalStatus" :txt="$t('dataset_data_dele_tip')" :loading="modalLoading" @cancel="cancelDele" @confirm="confirmDele"></DeleModal>
    </div>
  </div>

</template>

<script>
import DeleModal from '../../components/deleteModal.vue';
import util from '@/utils/tools.js';
export default {
  name: "role",
  data(){
    return{
      columns: [
        {
          title: this.$t('dataset_dataCorrection_table_index'),
          key:'id',
          render: (h, para) => {
            return h('span', para.row.id );
          }
        },
        {
          title: this.$t('dataset_dataCorrection_table_dataid'),
          key:'id',
          render: (h, para) => {
            return h('span', para.row.data.id );
          }
        },
        {
          title: this.$t('dataset_dataCorrection_table_title'),
          key: 'title',
          render: (h, para) => {
            return h('router-link', {
              props: {
                to: {
                  name: 'data-detail',
                  params: {
                    id: para.row.data.id,
                    tab:'cont'
                  }
                }
              },
              style:{
                color:'#2db7f5',
              }
            }, para.row.data.title);
          }
        },
        {
          title: this.$t('dataset_collect_table_category'),
          key: 'category',
          render: (h, para) => {
            let categorys = para.row.categoryList.map((item)=>{
              return item.name;
            }).join('-');
            return h('span', categorys);
          }
        },
        {
          title: this.$t('dataset_dataCorrection_table_type'),
          key: 'type',
          render: (h, para) => {
            return h('span', this.typeObj[para.row.data.type]);
          }
        },
        {
          title: this.$t('dataset_data_table_level'),
          key: 'level',
          render:(h,para)=>{
            return h('span',this.levelObj[para.row.data.level])
          }
        },
        {
          title: this.$t('dataset_dataCorrection_table_created_at'),
          key: 'created_at',
          render: (h, para) => {
            return h('span', util.timeFormatter(
                    new Date(+para.row.created_at * 1000), 'MM-dd hh:mm'
                )
            );
          }
        },
        {
          title: this.$t('dataset_dataCorrection_table_op'),
          render: (h, params) => {
            return h('div', [
              h('Button', {
                props: {
                  type: 'error',
                  size: 'small'
                },
                on: {
                  click: () => {
                    this.delete(params.row);
                  }
                }
              }, this.$t('system_dele'))
            ]);
          }
        }
      ],
      data: [],
      loading:false,
      deleteComfirmModal:true,
      deleModalStatus:false,
      modalLoading:false,
      curDeleData:{},
      page:1,
      pageSize:10,
      total:0,
      modalStatus:false,
      formItem:{
        id:'',
        name:'',
        data_count:'',
        desc:'',
      },
      ruleValidate: {
        id: [
          { required: true, message: this.$t('dataset_dataCorrection_add_id_tip'), trigger: 'blur' }
        ],
        name: [
          { required: true, message: this.$t('dataset_dataCorrection_add_name_tip'), trigger: 'blur' }
        ],
        data_count: [
          { message: this.$t('dataset_dataCorrection_add_data_count_tip'), trigger: 'blur' }
        ],
        desc: [
          { message: this.$t('dataset_dataCorrection_add_desc_tip'), trigger: 'blur' },
        ],
      },
      addBtnLoading:false,
      type:'',
      modalTit:'',
      typeObj:{},
      levelObj:{},
    }
  },
  components:{
    DeleModal
  },
  mounted(){
    this.getList();
  },
  methods:{
    getList(){
      this.loading = true;
      let params = {
        page:this.page,
        pageSize:this.pageSize
      };
      this.api.dataset.dataUserCollect(params).then((res)=>{
        this.loading = false;
        if(!res.list.length && this.page > 1){
          this.page = this.page - 1;
          this.getList();
          return;
        }
        this.data = res.list;
        this.total = Number(res.count);
        this.typeObj = res.types;
        this.levelObj = res.levels;
      }).catch((err)=>{
        this.loading = false;
      })
    },
    delete(data){
      this.curDeleData = data;
      this.deleModalStatus = true;
    },
    cancelDele(){
      this.deleModalStatus = false;
    },
    confirmDele(){
      let data = {
        id:this.curDeleData.id
      }
      this.modalLoading = true;
      this.api.dataset.dataUserCollectDelete(data).then((res)=>{
        this.modalLoading = false;
        this.deleModalStatus = false;
        this.getList();
      }).catch((err)=>{
        this.modalLoading = false;
      });
    },
    changePage(page){
      console.log(page,'changePage')
      this.page = page;
      this.getList();
    },
    cancel(){
      this.modalStatus = false;
      this.$refs.formCustom.resetFields();
    }
  }
}
</script>

<style scoped lang="scss">
.role-main{
  margin: 20px;
  padding:20px;
  background: #FFFFFF;
  box-shadow: 0px 2px 12px 0px rgba(189,189,189,0.4100);
  border-radius: 4px;
  .top{
    text-align: left;
    margin-bottom: 20px;
  }
  .page{
    margin-top: 20px;
    text-align: right;
  }
}
</style>
